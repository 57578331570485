import * as React from "react";
import * as Rx from "rxjs";
import { graphql, Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import algoliasearch from "algoliasearch";
// @ts-ignore
import AngoliaLogo from "@images/search/angolia.png";
import { debounceTime } from "rxjs/operators";
import { titleCase } from "title-case";
import { seoAtom, heroAtom } from "@/store";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { Container } from "@components/Container";
import { Layout } from "@components/Layout";
import { Heading } from "@components/Heading";
import { Helmet } from "react-helmet";
import { useHydrateAtoms } from "jotai/utils";
import { env } from "@/constants/env";

const ANGOLIA_APP_ID = env.GATSBY_ALGOLIA_APP_ID;
const ANGOLIA_SEARCH_KEY = env.GATSBY_ANGOLIA_SEARCH_KEY;
const ANGOLIA_INDEX = env.GATSBY_ANGOLIA_INDEX;

type EP3Result = {
  id: string;
  title: string;
  url: string;
  summary?: string;
  tags?: string[];
  headerTag: string;
  displayDescription: string;
};

const adjustHighlight = (str: string) => {
  return str.replace(/<\/em>/g, "").replace(/<em>\w*/g, "$&</em>");
};

const Tag: React.FC = (props) => <span className="mx-3">{props.children}</span>;

const SearchResult: React.FC<{ result: EP3Result }> = ({ result }) => {
  switch (result.headerTag) {
    case "Goal Map":
      return <GoalMapResult result={result} />;
    default:
      return <StandardResult result={result} />;
  }
};

const ResultLayout = ({ result, children }: any) => {
  return (
    <>
      <hr style={{ width: "97%", margin: "auto" }} />
      <div className="hover:shadow-lg hover:bg-sky-100 border-top">
        <div className="text-xl p-2 font-semibold flex justify-between">
          <span>{result.title}</span>
          <div className="flex flex-col items-end">
            <span className="text-sm">{result.headerTag}</span>
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

const StandardResult = ({ result }: { result: EP3Result }) => {
  // result?._snippetResult?.summary?.value
  const resultDescription = result?.displayDescription;
  const tags = result.tags;
  return (
    <li>
      {/* @ts-ignore */}
      <Link to={result.url}>
        <ResultLayout result={result}>
          <div
            className="search-result-text text-md ml-4 p-2 text-black"
            dangerouslySetInnerHTML={{ __html: resultDescription }}
          />
          {tags && tags.length > 0 && (
            <div className="flex flex-start ml-4 items-center ">
              {/* @ts-ignore */}
              <FontAwesomeIcon icon={faTag} size="1x" className={`mr-2 text-tertiary`} />
              {tags.map((tag, idx) => (
                <Tag key={`tag-${idx}`}>{tag}</Tag>
              ))}
            </div>
          )}
        </ResultLayout>
      </Link>
    </li>
  );
};

const GoalMapResult = ({ result }: any) => {
  return (
    <>
      {result.slugs.map((slug: { current: string }) => (
        <li key={`slug-${slug.current}`}>
          {/* @ts-ignore */}
          <Link to={"/goal-map/" + slug.current}>
            <ResultLayout result={result}>
              <div className="search-result-text text-md ml-4 p-2 text-black">
                Go to Goal Map - {titleCase(slug.current.replace(/-/g, " "))}
              </div>
            </ResultLayout>
          </Link>
        </li>
      ))}
    </>
  );
};

const SearchResults: React.FC<{ results: EP3Result[] | null }> = ({ results }) => {

  const filteredResults = results || []; //.filter((x) => x.headerTag !== "Goal Map");
  return (
    <div>
      <div>
        <span className={`${filteredResults.length === 0 && "text-quaternary font-bold"}`}>

          Results {filteredResults.length}
        </span>
      </div>
      <ul className="list-none">
        {filteredResults.map((result) => (
          <SearchResult key={`search-result-${result.id}`} result={result} />
        ))}
      </ul>
    </div>
  );
};
const searchAngolia = (client: any, queryStr: string) =>
  client
    .initIndex(ANGOLIA_INDEX)
    .search(queryStr, {
      attributesToRetrieve: [
        "title",
        "tags",
        "headerTag",
        "description",
        "toolkitsSummary",
        "organization",
        "role",
        "benefits",
        "programmaticAssessments",
        "effectivePractices",
        "resources",
        "evidence",
        "url",
        "slugs",
      ],
      attributesToHighlight: [
        "description",
        "toolkitsSummary",
        "organization",
        "role",
        "benefits",
        "programmaticAssessments",
        "effectivePractices",
        "resources",
        "evidence",
      ],
      attributesToSnippet: [
        "description: 50",
        "toolkitsSummary: 50",
        "benefits: 50",
        "programmaticAssessments: 50",
        "effectivePractices: 50",
        // "resources: 20",
        // "evidence: 20",
      ],
      hitsPerPage: 100,
    })
    .then(({ hits }: { hits: EP3Result[] }) => {
      const query = queryStr.toLowerCase().trim().split(" ");
      return hits
        .filter((x) => x.title)
        .map((hit) => {
          const { _snippetResult } = hit as any;
          const displayStr = Object.values(_snippetResult).reduce((str: string, x) => {
            const { value, matchLevel } = x as { matchLevel: string, value: string }
            return matchLevel !== "none" ? (str.endsWith("...") ? str.slice(0, -4) : str) + " " + value : str;
          }, "");
          const titleHits = query.reduce((acc, x) => (hit.title.toLowerCase().includes(x) ? acc + 1 : acc), 0);
          return {
            ...hit,
            titleHits,
            displayDescription: displayStr ? adjustHighlight(displayStr) : "",
          };
        });
    })
    .then((hits: any[]) => {
      // Added because there is no way to weight the title, which seems to be very important

      return hits.sort((a: any, b: any) => {
        return a.titleHits === b.titleHits ? 0 : a.titleHits < b.titleHits ? 1 : -1;
      });
    });

const GoogleAnalyticsCall = (value: any) => {
  // @ts-ignore
  window?.dataLayer?.push({
    event: "customSearch",
    customSearchInput: value,
  });
};

function insertUrlParam(key: string, value: string) {
  if (!window.history.pushState) {
    return value;
  }

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  const newurl =
    window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
  window.history.pushState({ path: newurl }, "", newurl);
  return value;
}

const queryString = (): string => {
  if (typeof window !== "object") {
    return "";
  }
  const searchParams = new URLSearchParams(window.location.search);
  return (searchParams.get("q") as string) || ("" as string);
};

const useAngolia = (): [EP3Result[] | null, string, (event: React.ChangeEvent) => void] => {
  const [results, updateResults] = React.useState<EP3Result[] | null>(null);
  const baseValue = React.useMemo(() => queryString(), []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = React.useState(baseValue);
  // @ts-ignore
  React.useEffect(() => {
    if (value.trim() === "") {
      updateResults(null);
      return;
    }
    const client = algoliasearch(ANGOLIA_APP_ID, ANGOLIA_SEARCH_KEY);
    const queryEvent = Rx.from(searchAngolia(client, value));
    const stream = queryEvent.pipe(debounceTime(2000));

    const subscription = stream.subscribe((values) => {
      // @ts-ignore
      updateResults(values);
      insertUrlParam("q", value);
      GoogleAnalyticsCall(value);
    });

    return () => {
      return subscription.unsubscribe();
    };
  }, [value]);

  return [
    results,
    value,
    (evt) => {
      // @ts-ignore
      setValue(evt.target.value);
    },
  ];
};

type SearchPageProps = {
  data?: { hero?: string | any };
};

const SearchPage = ({ data }: SearchPageProps) => {
  const [results, query, onQueryUpdate] = useAngolia();
  const searchContainerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!searchContainerRef.current) {
      return;
    }
    searchContainerRef.current.scrollIntoView({ behavior: "smooth" });
  }, [results]);
  const seo = { title: "Search EP3", description: "Search EP3" };
  const hero = data?.hero ?? null;
  useHydrateAtoms([
    [seoAtom, seo],
    [heroAtom, hero],
  ])
  return (
    <>
      <Helmet>
        <title> Search | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <Title><span className="text-white">Search</span></Title>
          </Header>
          <div ref={searchContainerRef} className="p-8 my-8 bg-blue-200">
            <Heading level={2}>What are you looking for?</Heading>
            <input
              id="site-search"
              type="Search"
              onChange={onQueryUpdate}
              defaultValue={query}
              className="w-full p-3 text-lg border border-1"
              placeholder="e.g. increase the number of majors, career preparation, equity"
            />
            <img className="float-right" src={AngoliaLogo} alt="Powered By Angolia Search" />
          </div>
          <SearchResults results={results} />
        </Container>
      </Layout>
    </>
  );
};

export default SearchPage;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "hero-search-page.jpg" }) {
      ...heroImageFragment
    }
  }
`;
